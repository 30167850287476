import _client from "./client";
import _colors from "colors";
import _has from "./has";
import _is from "./is";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var client = _client,
    colors = !client && _colors,
    has = _has,
    is = _is;
exports = colorfill();

function colorfill() {
  /* istanbul ignore next */
  ["red", "green", "bold", "grey", "strip"].forEach(function (color) {
    !is.str(String.prototype[color]) && Object.defineProperty(String.prototype, color, {
      get: function () {
        return String(this || _global);
      }
    });
  });
}

export default exports;